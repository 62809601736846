import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import I18n from 'utils/i18n.js.erb';
import { linkWithParams } from 'routes';
import { rawHtml } from 'utils/Formatters';
import { getIsStudentSubdomain } from '../../selectors';

const LessonCard = ({ resource }) => {
  const isStudentSubdomain = useSelector(getIsStudentSubdomain);
  return (
    <li className="u-od-cs-bg--light u-od-custom-link o-od-item-card small-12">
      <Link
        to={{ pathname: linkWithParams('lesson', resource.hierarchy) }}
        className="o-od-card"
      >
        <div className="row is-collapse-child align-middle align-justify">
          <h3 className="u-od-txt--card-header">
            {I18n.t('ui.hierarchy.lesson', { num: resource.number })}
          </h3>
          {!isStudentSubdomain && (
            <div className="o-od-item-card__label u-od-txt--card-type text-center u-od-cs-bg--dark-blue u-od-cs-txt--light">
              {resource.lessonType}
            </div>
          )}
        </div>
        {!isStudentSubdomain && (
          <div
            className={`row is-collapse-child align-middle ${
              isEmpty(resource.focus) ? 'align-right' : 'align-justify'
            }`}
          >
            {isEmpty(resource.focus) ? null : (
              <span className="u-od-txt--h3">{resource.focus}</span>
            )}
            {isEmpty(resource.monitorMoment) ? null : (
              <span className="u-od-txt--h2 u-od-txt--upcase align-self-end">
                {resource.monitorMoment}
              </span>
            )}
          </div>
        )}
        <div className="row is-collapse-child align-middle align-justify">
          {rawHtml(
            resource.description,
            'u-od-txt--card u-od-margin-top--small'
          )}
        </div>
      </Link>
    </li>
  );
};

LessonCard.propTypes = {
  resource: PropTypes.object.isRequired,
};

export default LessonCard;
