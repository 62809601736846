import React from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import { useSelector } from 'react-redux';
import { getIsStudentSubdomain } from '../../selectors';
import I18n from '../../utils/i18n.js.erb';
import { MaterialsList } from '../common/MaterialsList';

const GuidebookMaterials = ({ resource }) => {
  const isStudentSubdomain = useSelector(getIsStudentSubdomain);
  const materials = isStudentSubdomain
    ? filter(resource.materials, 'showStudent')
    : resource.materials;
  const materialsBlock = () => {
    return (
      <div className="row u-od-full-width">
        <aside className="o-od-panel__aside">
          <h2>{I18n.t('ui.guidebook.tabs.materials')}</h2>
        </aside>
        <section className="o-od-panel__content">
          <MaterialsList materials={materials} resourceType="unit" />
        </section>
      </div>
    );
  };
  return <div>{materialsBlock()}</div>;
};

GuidebookMaterials.propTypes = {
  resource: PropTypes.object.isRequired,
};

export default GuidebookMaterials;
