import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { map } from 'lodash';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { linkWithParams } from 'routes';
import I18n from '../../utils/i18n.js.erb';

const ARROW_ICONS = ['od-icon-chevron-left', 'od-icon-chevron-right'];

const LevelArrows = ({ next, previous }) => {
  const location = useLocation();
  return map([previous, next], (r, idx) => {
    const cls = classNames('o-od-breadcrumb--arrow', {
      'u-od-disabled': !r,
    });
    const clsLink = classNames('u-od-cs-bg--light', {
      'u-od-nopointer': !r,
    });

    const pathname = r
      ? linkWithParams(r.odellCurriculumType, r.hierarchy)
      : '';
    return (
      <li key={`arrow-${idx}`} className={cls}>
        <Link
          className={clsLink}
          to={{ pathname, search: location.search }}
          title={I18n.t(`ui.nav.level_arrows.${idx === 0 ? 'left' : 'right'}`)}
          aria-label={I18n.t(
            `ui.nav.level_arrows.${idx === 0 ? 'left' : 'right'}`
          )}
        >
          <i
            className={`u-od-txt--icon-base ${ARROW_ICONS[idx]}`}
            aria-hidden="true"
          ></i>
        </Link>
      </li>
    );
  });
};

LevelArrows.propTypes = {
  next: PropTypes.object,
  previous: PropTypes.object,
};

export default LevelArrows;
