import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ActivityLabels = ({ activity }) => {
  const isActivitySpecialLabel = false;
  const clsType = classNames(
    'o-od-item-card__label align-center u-od-flex-inline u-od-txt--card-type u-od-cs-txt--light u-od-margin-bottom--small',
    {
      'u-od-cs-bg--dark-blue': !isActivitySpecialLabel,
      'u-od-cs-bg--blue': isActivitySpecialLabel,
    }
  );

  return (
    <div className="o-od-activity__labels text-center">
      <div className={clsType}>{activity.activityType}</div>
      <div className="u-od-txt--h3 u-od-margin-bottom--small text-center">
        {activity.focus}
      </div>
    </div>
  );
};

ActivityLabels.propTypes = {
  activity: PropTypes.object.isRequired,
};

export default ActivityLabels;
