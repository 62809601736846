import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ExtendedRoute, { appNamedRoutes, staticRoutes } from '../routes';
import ResourcePage from './ResourcePage';
import HomePage from './HomePage';
import NotFound from '../components/NotFound';
import ScrollToTop from '../components/ScrollToTop';
import StaticPage from './StaticPage';
import LiteracyToolboxPage from './LiteracyToolboxPage';

const App = () => {
  const prefix = '/(lti/schoology)?';

  return (
    <div>
      <ScrollToTop />
      <Switch>
        <Route path={prefix + appNamedRoutes.root} exact component={HomePage} />
        <ExtendedRoute
          path={staticRoutes.about}
          exact
          component={StaticPage}
          pageType="about"
        />
        {/* Temporary disable IWU route (see https://github.com/learningtapestry/odell-tea-lcms/issues/4) */}
        {/*<ExtendedRoute*/}
        {/*  path={staticRoutes.about_iwu}*/}
        {/*  exact*/}
        {/*  component={StaticPage}*/}
        {/*  pageType="about_iwu"*/}
        {/*/>*/}
        <ExtendedRoute
          path={staticRoutes.literacy_toolbox}
          exact
          component={LiteracyToolboxPage}
        />
        <ExtendedRoute
          path={staticRoutes.privacy}
          exact
          component={StaticPage}
          pageType="privacy"
        />
        <ExtendedRoute
          path={staticRoutes.terms}
          exact
          component={StaticPage}
          pageType="terms"
        />
        <ExtendedRoute
          path={prefix + appNamedRoutes.grade}
          exact
          component={ResourcePage}
          resourceType="grade"
          withSubNavBar={false}
          withTopBar={true}
        />
        <ExtendedRoute
          path={prefix + appNamedRoutes.guidebook}
          exact
          component={ResourcePage}
          resourceType="guidebook"
          withSubNavBar={false}
          withTopBar={true}
        />
        <ExtendedRoute
          path={prefix + appNamedRoutes.section}
          exact
          component={ResourcePage}
          resourceType="section"
          withSubNavBar={true}
          withTopBar={true}
        />
        <ExtendedRoute
          path={prefix + appNamedRoutes.lesson}
          exact
          component={ResourcePage}
          resourceType="lesson"
          withSubNavBar={true}
          withTopBar={true}
        />
        <ExtendedRoute
          path={prefix + appNamedRoutes.activity}
          exact
          component={ResourcePage}
          resourceType="activity"
          withSubNavBar={true}
          withTopBar={false}
        />
        <ExtendedRoute
          path={prefix + appNamedRoutes.step}
          exact
          component={ResourcePage}
          resourceType="activity"
          withSubNavBar={true}
          withTopBar={false}
        />
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

export default App;
